import React from 'react'

const Certifications = (props) => {
  return (
    <div id='certifications' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>Certifications</h2>
          <h3>We received the highest level of certifications for its products by renowned laboratories of the world. Each of its products is a result of intensive research and represents cutting-edge technology.</h3>

        </div>
        <div className="certificationimg col-lg-4">
          <p ><img src="img/certificate/trademarkrb.png" alt="trademark" width="200px" height="200px" /></p>
        </div>
        <div className="certificationimg col-lg-4">
          <p><img src="img/certificate/isocertificaterb.png" alt="isocertificate" width="200px" height="200px" /></p>
        </div>
        <div className="certificationimg col-lg-4">
          <p ><img src="img/certificate/consumerawardrb.png" alt="consumeraward" width="200px" height="200px" /></p>
        </div>

      </div>
    </div>
  )
}

export default Certifications;

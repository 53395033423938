export const About = (props) => {
  return (
    <div id="about">
    <title>We deals with top ro company</title>
    <meta name="description" content="We deals with kent,ao smith,hul pureit, livepure,aquaguard ro water purifier."></meta>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/ro-water-about.webp" className="img-responsive" alt="ro-repair-service-about-us" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

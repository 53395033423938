import React from 'react';
import Card from './Card';

export const Products = (props) => {
  return (
    <div id="products" className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>We Deals With</h2>
        </div>
        <div className="container-fluid mb-5" >
          <div className='row'>
            <div className="col-10 mx-auto">
              <div className="row gy-6">
                {props.data
                  ? props.data.map((d, i) => {
                    return <Card key={i}
                      imgsrc={d.imgsrc}
                      title={d.title}

                    />;
                  })
                  : 'Loading...'
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/*detail={d.detail}  =>for detail */


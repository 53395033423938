import { Link } from 'react-router-dom';
export const PageNotFound = (props) => {
    return (
        <div className='text-center' style={{marginTop:'80px',display:'table'}}>
        <div className="container">
            <h1>404 Error Page Not Found</h1>
            <h1>Something Went Wrong</h1>
            <h4>“The page you are trying to access doesn’t exist”</h4>
            <Link to="/" className='btn' style={{color:'red'}}><b><u>Go Home</u></b></Link>
        </div>
        </div>
    )
}
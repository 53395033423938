import { useNavigate } from "react-router-dom";
import emailjs from 'emailjs-com'
import React, { useRef, useState } from 'react';

const initialState = {
  from_name: '',
  mobile: '',
  email: '',
  purpose:'',
  adress: '',
}
export const Contact = (props) => {
  const [{ from_name, purpose, mobile, email, adress }, setState] = useState(initialState)
  const formRef = useRef();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { from_name, value } = e.target
    setState((prevState) => ({ ...prevState, [from_name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(from_name,email,purpose, mobile, adress)
    emailjs
      .sendForm(
        'service_meutgk9', 'template_tpyd7p2', e.target, 'qsHQwNEJt4WdmHcOe'
      )
      .then(
        (result) => {
          alert('Your Call Back Request has been submitted , you will get a get from our team within 5-10 min. Thanks for your patience')
          clearState();
          formRef.current.reset();
          navigate("/thanks");
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-6'>
            <div className='row'>
              <div className='section-title'>
                <h2>SUBMIT A CALL BACK REQUEST</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name='sentMessage' onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='from_name'
                        className='form-control'
                        placeholder='Name*'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>


                      <select className="form-select form-control" id='purpose' name='purpose' aria-label="Purpose" onChange={handleChange} style={{color:'red'}}>
                        <option value="ro">RO / Water purifier</option>
                        <option selected value="ac">Air Conditioner</option>
                        <option value="washing-machine">Washing Machine</option>
                        
                        <option value="chimney">Chimney Service</option>
                        <option value="Refrigerator">Refrigerator</option>
                        <option value="microwave">Microwave oven</option>

                      </select>

                      <p className='help-block text-danger'></p>

                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='number'
                        id='mobile'
                        name='mobile'
                        className='form-control'
                        placeholder='Mobile Number*'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>

                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email*'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>

                    </div>
                  </div>
                </div>


                <div className='form-group'>
                  <textarea
                    name='adress'
                    id='adress'
                    className='form-control'
                    rows=''
                    placeholder='Address'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg' style={{ marginLeft: '20px' }}>
                  Get a Call Back From Us
                </button>
                <div><p style={{ marginLeft: '150px' }}>OR</p></div>
                <a className="btn btn-custom btn-lg" href="tel:7762998962" style={{ marginLeft: '15px' }} > Call directly @ <i className="fa fa-phone" ></i> 7762998962</a>
              </form>
            </div>
          </div>

          <div className='col-md-5 col-md-offset-1 contact-info'>
            <div className='contact-item'>
            <h4>Get Your Air Conditioner Sales & Services All Over India. BOOK NOW </h4>
              <img src="img/contactbrokberry.jpg" className="img-responsive" alt="water purifier" />{" "}
            </div>
          </div>
          {/*
         <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div> */}
          {/*
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        */}
        </div>
      </div>

    </div>
  )
}

import { useState, useEffect } from "react";
import { Header } from "../acservice/header";
import { About } from "../acservice/about";
import { Features } from "../acservice/features";
import { Choosetext } from "../acservice/choosetext";
import { Services } from "../acservice/services";
import { Products } from "../acservice/products/Products";
import { Detailtext } from "../acservice/detalitext";
import Certifications from "../acservice/Certifications";
import { Pricetag } from "../acservice/pricetag/pricetag";
import { Contact } from "../acservice/contact";
import { Faq } from "../acservice/faq";
import { Testimonials } from "../acservice/testimonials";
import JsonData from "../../data/data.json";
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});


const AcServices = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);

    return (
        <div>
            <Header data={landingPageData.AcHeader} />
            <About data={landingPageData.AcAbout} />
            <Features data={landingPageData.AcFeatures} />
            <Choosetext />
            <Services data={landingPageData.AcServices} />
            <Products data={landingPageData.AcProducts} />
            <Detailtext />
            <Certifications />
            <Pricetag data={landingPageData.AcPricetag} />


            <Contact data={landingPageData.AcContact} />
            <Faq />
            <Testimonials data={landingPageData.AcTestimonials} />
            {/*<Crous /> 
            <Blog data={landingPageData.Blog} />
            <Crous2 /> 
            <Caurousel />
            <Branch data={landingPageData.Branches} />
            
            <SocialBlog data={landingPageData.Features} />
            for whatspp web use link href="https://wa.me/9060591201"
            <Team data={landingPageData.Team} />
            
            
            <Chooseus /> */}
        </div>
    )
}

export default AcServices;

export const Choosetext = (props) => {
    return (
        <div id="detailtext">
            <div className="container">
                <div className="row row-reverse">

                    <div className="common-section brandss-section-here" >
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content-area-">
                                    <h4><strong>Our range of services covers everything from installation and maintenance to repairs and replacements. We work with all major brands and models of air conditioners, so you can trust us to handle your equipment with expertise and care.</strong></h4>
                                    <h3><u>When you choose Brokberry AC Services, you can expect:</u></h3>
                                    <ul>
                                        <li><b><u>Expert Technicians:</u></b> Our technicians are highly trained professionals with years of experience in the field. They have the skills and knowledge to tackle any AC-related issue efficiently and effectively.</li>
                                        <li><b><u>Prompt Service:</u></b> We understand that AC problems can arise at any time, which is why we offer fast and reliable service. Whether you need emergency repairs or routine maintenance, you can count on us to respond promptly to your call.</li>
                                        <li><b><u>Quality Workmanship:</u></b> We take pride in the quality of our workmanship, ensuring that every job is done to the highest standards. From installation to repairs, we use only the best materials and techniques to ensure long-lasting results.</li>
                                        <li><b><u>Transparent Pricing:</u></b> At Brokberry, we believe in transparency and honesty. That's why we provide upfront pricing for all our services, with no hidden fees or surprises. You'll know exactly what to expect before we begin any work.</li>
                                        <li><b><u>Customer Satisfaction:</u></b> Your satisfaction is our top priority. We go above and beyond to ensure that every customer is happy with our services. From the moment you contact us to the completion of the job, we strive to exceed your expectations.</li>
                                        <p><i className="fa fa-circle"></i>&nbsp;With Brokberry AC Services, you can enjoy peace of mind knowing that your air conditioning system is in good hands. Whether you need a new AC installed, routine maintenance performed, or repairs completed, we're here to help. Contact us today to schedule an appointment and experience the difference of working with Brokberry AC Services.</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
import { Helmet } from "react-helmet";
import { useState, useEffect } from 'react'
import emailjs from 'emailjs-com'
import React, { useRef } from 'react';
import Biharnearme from "./nearmelink/Biharnearme";
import locdata from "../../data/locdata.json";
import Popularcity from "./nearmelink/popularcity";


const initialState = {
    from_name: '',
    mobile: '',
    adress: '',
}
export const Usefulnearme = ({ title, number }) => {


    const [{ from_name, mobile, adress }, setState] = useState(initialState)
    const handleChange = (e) => {
        const { from_name, value } = e.target
        setState((prevState) => ({ ...prevState, [from_name]: value }))
    }
    const clearState = () => setState({ ...initialState })

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(from_name, mobile, adress)
        emailjs
            .sendForm(
                'service_meutgk9', 'template_tpyd7p2', e.target, 'qsHQwNEJt4WdmHcOe'
            )
            .then(
                (result) => {
                    alert('Your Request Has Been Submitted')
                    clearState(e);
                },
                (error) => {
                    console.log(error.text)
                }
            )
    }
    return (
        <>
            <div id="differntlocation">
                <Helmet>
                    <title>RO Service {title} @{number} | Water Purifier Service</title>
                    <meta name="keywords" content="ro service, water purifier service, ro repair, ro service near me, water purifier service near me, ro service center, ro service center near me, ro service centre, ro service centre near me" />
                    <meta name="author" content="h2oro service is best ro repair and maintanancce service in india" />
                    <meta name="description" content="RO Service {title}: We are India's leading RO water purifier service provider of all brands like Kent, Pureit, Livpure, Aqua Guard, Aqua Fresh etc. RO repair service in {title}."></meta>
                </Helmet>
                <div className="navigation-wrapper">
                    <div className="Container fluid ">
                        <header className="location-header" >
                            <div className="container h-100">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="text-center align-items-center">
                                            <div className="w-100 text-onbanner">
                                                <h1 className="display-3"> Water Purifier Service In {title} @7762998962</h1>
                                            </div>
                                            <div className="call-action-area">
                                                <span className="number"><a href="tel:7762998962 " style={{ fontWeight: 'bold' }}><i className="fa fa-phone"></i>  +91-7762998962 </a></span>
                                                <span className="number"><a href="https://api.whatsapp.com/send?phone=917762998962" target="_blank" style={{ fontWeight: 'bold' }}><i className="fa fa-whatsapp" style={{ backgroundColor: '#25D366' }}></i>  +91-7762998962 </a></span>
                                                {/*<span className="request"><a data-toggle="modal" data-target="#newModalForWizard">Request a Call Back</a></span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 locform">
                                        <div className='row'>
                                            <div className='section-title'>
                                                <h2>SUBMIT A CALL BACK REQUEST</h2>
                                                <p>
                                                    Please fill out the form below to send us an email and we will
                                                    get back to you as soon as possible.
                                                </p>
                                            </div>
                                            <form name='sentMessage' onSubmit={handleSubmit}>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className='form-group'>
                                                            <input
                                                                type='text'
                                                                id='name'
                                                                name='from_name'
                                                                className='form-control'
                                                                placeholder='Full Name*'
                                                                required
                                                                onChange={handleChange}
                                                            />
                                                            <p className='help-block text-danger'></p>
                                                            <input
                                                                type='number'
                                                                id='mobile'
                                                                name='mobile'
                                                                className='form-control'
                                                                placeholder='Mobile Number*'
                                                                required
                                                                onChange={handleChange}
                                                                maxLength={10}
                                                                minLength={10}

                                                            />
                                                            <p className='help-block text-danger'></p>
                                                            <div className='form-group'>
                                                                <textarea
                                                                    name='adress'
                                                                    id='adress'
                                                                    className='form-control'
                                                                    rows='3'
                                                                    placeholder='Address'

                                                                    onChange={handleChange}
                                                                ></textarea>
                                                                <p className='help-block text-danger'></p>
                                                            </div>
                                                            <div id='success'></div>

                                                        </div>
                                                    </div>


                                                </div>

                                                <div id='success'></div>
                                                <button type='submit' className='btn btn-custom btn-lg' style={{ marginLeft: '20px' }}>
                                                    Get a Call Back From Us
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>

                        <section id="Aqufresh-content-area ">
                            <div className="container">
                                <div className="row row-reverse">

                                    <div className="common-section brandss-section-here" >
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="content-area-">
                                                    <h2><strong>RO Water Purifier Service in {title}</strong></h2>

                                                    <p><a href="/ro-service"><strong>RO services </strong></a>&nbsp;in {title} play a vital role in ensuring safe drinking water, addressing the pressing need for clean water amidst water quality concerns. With increasing awareness about health hazards related to contaminated water,&nbsp;<a href="/buy-water-purifier"><strong>water purifier services </strong></a>&nbsp; in {title} have become indispensable. </p>

                                                    <p>These services offer comprehensive solutions, from installation to maintenance, catering to diverse needs. {title}'s &nbsp;<a href="/ro-service"><strong>RO services</strong></a>&nbsp;employ advanced technology to eliminate impurities, providing households and businesses with access to clean, healthy water. In a city where water quality is often questionable, these services stand as a shield, safeguarding health, and well-being. Trustworthy &nbsp;<a href="/ro-service"><strong>RO services </strong></a>&nbsp;in {title} are indispensable for a healthier tomorrow.</p>

                                                    <p><strong>RO Service | RO Service Center Number&nbsp;{number}</strong></p>

                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-responsive">
                                                            <thead>
                                                                <tr className="table-success">
                                                                    <td><strong>#</strong></td>
                                                                    <td><strong>RO Water Purifier Service</strong></td>
                                                                    <td><strong>Contact Number</strong></td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr className="table-success">
                                                                    <td><strong>1</strong></td>
                                                                    <td>RO Service&nbsp;{title}</td>
                                                                    <td><a href="tel:{number}">7762998962</a></td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td><strong>2</strong></td>
                                                                    <td>Water Purifier Service&nbsp;{title}</td>
                                                                    <td><a href="tel:7762998962">7762998962</a></td>
                                                                </tr>
                                                                <tr className="table-success">
                                                                    <td><strong>3</strong></td>
                                                                    <td>Water Purifier Service Near Me&nbsp;{title}</td>
                                                                    <td><a href="tel:7762998962">7762998962</a></td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td><strong>4</strong></td>
                                                                    <td>RO Service Near Me in&nbsp;{title}</td>
                                                                    <td><a href="tel:7762998962">7762998962</a></td>
                                                                </tr>
                                                                <tr className="table-success">
                                                                    <td><strong>5</strong></td>
                                                                    <td>RO Repair Service&nbsp;{title}</td>
                                                                    <td><a href="tel:7762998962">7762998962</a></td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td><strong>6</strong></td>
                                                                    <td>RO Installation service in&nbsp;{title}</td>
                                                                    <td><a href="tel:7762998962">7762998962</a></td>
                                                                </tr>
                                                                <tr className="table-success">
                                                                    <td><strong>7</strong></td>
                                                                    <td>RO Service Toll Free Number in&nbsp;{title}</td>
                                                                    <td><a href="tel:7762998962">7762998962</a></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <h3><strong>Why RO Service In {title}?</strong></h3>

                                                    <p>The RO services in {title} that you get from H2oro services never compromise your health. We hire always a well-experienced professional who has a good background and have worked with customers in the past ad well. Our team also checks their training, knowledge and also work process to ensure that they can maintain the quality of the services that we promise to offer to our customers and clients. Our&nbsp;<a href="/ro-service"><strong>RO services</strong></a>&nbsp;are always premium quality service for our customers and clients. We handle all kinds of water purifiers and ensure that they purify water faster and easier.</p>

                                                    <p>H2oro Services is the lasttest and growing brand in the service industry where many customers get satisfied and happy with our services in {title}.</p>

                                                    <p><strong>RO Service Cost In {title}</strong></p>

                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-responsive">
                                                            <tbody>
                                                                <tr className="table-success">
                                                                    <td>
                                                                        <p><strong>RO Service Type</strong></p>
                                                                    </td>
                                                                    <td>
                                                                        <p><strong>Water Purifier Service Cost</strong></p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td>
                                                                        <p>RO service in {title}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p>299/- (Additional Charge For Spare Parts)</p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-success">
                                                                    <td>
                                                                        <p>Water Purifier Installation in {title}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p>499/-</p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td>
                                                                        <p>Un-installation &amp; Installation of RO Service in {title}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p>699/-</p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-success">
                                                                    <td>
                                                                        <p>Installation &amp; & Water Purifier Service in {title}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p>699/-</p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td>
                                                                        <p>Visiting Charges for RO Service in {title}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p>299/- (If No Service)</p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <h2></h2><h2><strong>Why H2O RO Services is best in {title}?</strong></h2>

                                                    <p><strong><a href="/buy-water-purifier">H2O RO Services </a></strong>&nbsp;stands out as the premier choice in {title} due to its unwavering commitment to excellence. With cutting-edge technology and a team of highly skilled professionals. </p>

                                                    <p><strong><a href="/buy-water-purifier">H2O RO Purifier services </a></strong>&nbsp;ensures the purest, safest drinking water for its customers. Their robust customer service ensures prompt assistance and satisfaction. Furthermore, their dedication to sustainability is evident through eco-friendly practices. </p>

                                                    <p><strong><a href="/buy-water-purifier">H2O RO Purifier service’s</a>&nbsp;</strong>reputation for reliability and affordability makes it the preferred option for households and businesses alike.</p>

                                                    <p>In {title}, <strong><a href="/buy-water-purifier">H2O RO Services </a>&nbsp;</strong>sets the standard for quality, reliability, and customer satisfaction, making it the undisputed leader in the &nbsp; <strong><a href="/Manufacturers">water purification industry. </a>&nbsp;</strong></p>



                                                    <h3><strong>Services Provided By Water Purifier Service Centre In {title}</strong></h3>

                                                    <p><strong><a href="/WaterPurifier">H2O RO Purifier service’s</a>&nbsp;</strong>have become an essential appliance in households and commercial establishments due to the increasing concerns about water quality. In a city like {title}, where water contamination issues are prevalent, the role of water purifiers is paramount. To ensure the proper functioning and maintenance of these devices,&nbsp;<strong><a href="/">H2O RO Purifier service’s</a>&nbsp;</strong> play a crucial role. Here's a comprehensive look at the services provided by water purifier service centres in {title}:</p>

                                                    <ul>
                                                        <li><b><u>Installation:</u></b><strong><a href="/ro-service"> H2O RO Purifier service’s</a>&nbsp;</strong>centres offer professional installation services to ensure that the purifier is set up correctly and functions efficiently from the start. Proper installation is crucial for optimal performance and longevity of the purifier.</li>
                                                        <li><b><u>Regular Maintenance:</u></b> Regular maintenance is essential to keep the water purifier in peak condition. Service centres in {title} provide periodic maintenance services, including filter replacements, cleaning of filters and tanks, checking for leaks, and ensuring that all components are functioning correctly.</li>
                                                        <li><b><u>Filter Replacement:</u></b> Filters are the heart of any water purifier system. Over time, filters accumulate impurities and contaminants, reducing their effectiveness. Service centres offer filter replacement services to ensure that the purifier continues to provide clean and safe drinking water.</li>
                                                        <li><b><u>Repair Services:</u></b> In case of any malfunction or breakdown, water purifier service centres in {title} provide prompt repair services. Trained technicians diagnose the issue and undertake the necessary repairs using genuine spare parts to restore the purifier to its optimal working condition.</li>
                                                        <li><b><u>AMC (Annual Maintenance Contract):</u></b> H2O RO service centres in {title} offer AMC plans wherein customers can opt for annual maintenance contracts for their water purifiers. These contracts typically include periodic maintenance visits, filter replacements, and priority service in case of emergencies, providing customers with peace of mind and hassle-free maintenance.</li>
                                                        <li><b><u>Water Quality Testing:</u></b> H2O RO service centre also offers water quality testing services to assess the level of contamination in the water supply. Based on the test results, they can recommend suitable purification solutions or additional filtration systems to ensure the water is safe for consumption.</li>
                                                        <li><b><u>Upgradation Services:</u></b> With advancements in technology, newer and more efficient <strong><a href="/WaterPurifier">water purifier</a>&nbsp;</strong> models are constantly being introduced into the market. Service centres offer upgradation services, allowing customers to upgrade their existing purifiers to newer models with enhanced features and better purification capabilities. </li>
                                                        <li><b><u>Customer Support:</u></b> Good customer support is essential for addressing any queries or concerns that customers may have regarding their water purifiers. Service centres in {title} provide prompt and efficient customer support through various channels, including phone, email, and in-person visits, ensuring a seamless experience for their customers.</li>
                                                        <li><b><u>Educational Workshops:</u></b> Some service centres organize educational workshops and awareness programs to educate customers about the importance of water purification and proper maintenance practices. These workshops help customers make informed decisions about their water purification needs and promote responsible water usage habits.</li>
                                                        <li><b><u>Emergency Services:</u></b> In cases of sudden breakdowns or emergencies such as water contamination incidents, service centres offer emergency repair and maintenance services to ensure that the purifier is up and running as quickly as possible, safeguarding the health of the users.</li>
                                                        <li>In conclusion, H2O water purifier service centres in {title} play a vital role in ensuring access to clean and safe drinking water for the residents. By offering a wide range of services including installation, maintenance, repair, and customer support, these service centres contribute significantly to the well-being and health of the community.</li>
                                                        <blockquote>
                                                            <p>Choose to hire professionals from H2O RO service centre in {title} and keep your water purifier in good condition. We promise to deliver exceptional results at an affordable price.</p>
                                                        </blockquote>
                                                    </ul>

                                                    <div id='collapseprovide' className='text-center'>
                                                        <div className='container'>
                                                            <div className='section-title'>
                                                                <h2 style={{ textAlign: 'center' }}>We Provide</h2>
                                                            </div>
                                                            <div className='row'>
                                                                <div id="accordion" className="card ">

                                                                    <div className="card-header col-xs-6 col-md-3" id="headingRepair">
                                                                        <a className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseRepair" aria-expanded="true" aria-controls="collapseRepair">
                                                                            <i className='fa fa-tools'></i>
                                                                            <h3>REPAIR</h3>
                                                                        </a>
                                                                    </div>
                                                                    <div className="card-header col-xs-6 col-md-3" id="headingService">
                                                                        <a className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseService" aria-expanded="false" aria-controls="collapseService">
                                                                            <i className='fa fa-gear'></i>
                                                                            <h3>SERVICE</h3>
                                                                        </a>
                                                                    </div>
                                                                    <div className="card-header col-xs-6 col-md-3" id="headingInstallation">
                                                                        <a className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseInstallation" aria-expanded="false" aria-controls="collapseInstallation">
                                                                            <i className='fa fa-archive'></i>
                                                                            <h3>INSTALLATION</h3>
                                                                        </a>
                                                                    </div>
                                                                    <div className="card-header col-xs-6 col-md-3" id="headinUninstallation">
                                                                        <a className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseUninstallation" aria-expanded="true" aria-controls="collapseUninstallation">
                                                                            <i className='fa fa-minus-circle'></i>
                                                                            <h3>UNINSTALLATION</h3>
                                                                        </a>
                                                                    </div>
                                                                    <div className="colapsebox">
                                                                        <div id="collapseRepair" className="collapse" aria-labelledby="headingRepair" data-parent="#accordion">
                                                                            <div className="card-body">
                                                                                <h3>Inspection of the RO Plant</h3>
                                                                                <ol>
                                                                                    <li>•	You switch off the plant and disconnect the power and water connections.</li>
                                                                                    <li>•	Slowly disassemble all the components of the ROplant and clean them.</li>
                                                                                    <li>•	Check the filter, membrane, pump, valve, tank, tube, and fitting of the plant.</li>
                                                                                    <li>•	For any leakage, rust, crack, or defect, if any problems occur, fix it or replace it.</li>
                                                                                    <li>•	Reconnect the restart the RO plant.</li>
                                                                                    <li>•	You check the performance of the plant, water pressure, TDS of water, pH of water, and taste of water.</li>
                                                                                    <li>•	After checking the plant, generate a report and give it to the customer.</li>
                                                                                </ol>
                                                                            </div>
                                                                        </div>
                                                                        <div id="collapseService" className="collapse" aria-labelledby="headingService" data-parent="#accordion">
                                                                            <div className="card-body">
                                                                                <h3>RO service means reverse osmosis service, which is a research process of its own and the importance of the service is because.</h3>
                                                                                <ol>
                                                                                    <li>•	Through RO service bacteria, viruses, metals, and unwanted elements present in the water are removed which makes the water healthy and pure.</li>
                                                                                    <li>•	The RO service provides water TDS level is low which improves water quality.</li>
                                                                                    <li>•	With the RO service, the pH of the water is balanced which makes the water neither too acidic nor too salty.</li>
                                                                                    <li>•	With the service, the taste and consistency of the water are better which makes the water drinkable.</li>
                                                                                    <li>•	With the service, you take care of the plant, and long-lasting capability increases which makes the RO more economical and environmental.</li>
                                                                                </ol>
                                                                            </div>
                                                                        </div>
                                                                        <div id="collapseInstallation" className="collapse" aria-labelledby="headingInstallation" data-parent="#accordion">
                                                                            <div className="card-body">
                                                                                <h3>Installation of the RO plant can be done in the following steps:</h3>
                                                                                <ol>
                                                                                    <li>•	Once the RO plant is fixed in a moist and dry place where drainage and electric connection are available.</li>
                                                                                    <li>•	You should read the manual given along with the RO and connect all the components properly.</li>
                                                                                    <li>•	You connect the RO plant to the water source and open the valve.</li>
                                                                                    <li>•	You connect the power source and switch on.</li>
                                                                                    <li>•	With the service, you take care of the plant, and long-lasting capability increases which makes the RO more economical and environmental.</li>
                                                                                    <li>•	You let the RO plant run for a few minutes and take a sample of the pure water.</li>
                                                                                    <li>•	You check the performance of the plant and in case of any problem or leakage stop it immediately and fix it.</li>
                                                                                </ol>
                                                                            </div>
                                                                        </div>
                                                                        <div id="collapseUninstallation" className="collapse" aria-labelledby="headingUninstallation" data-parent="#accordion">
                                                                            <div className="card-body">
                                                                                <h3>Uninstallation of the RO plant can be done in the following steps:</h3>
                                                                                <ol>
                                                                                    <li>•	Disconnect the RO plant from the power source and turn off the switch.</li>
                                                                                    <li>•	Disconnect the RO plant from the water source and close the valve.</li>
                                                                                    <li>•	You can slowly disassemble and clean all components of the RO plant.</li>
                                                                                    <li>•	Pack the RO plant securely and transport or store it elsewhere.</li>
                                                                                </ol>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <p><strong>How Water Purifier Service Near me Process Work?</strong></p>

                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-responsive">
                                                            <tbody>
                                                                <tr className="table-success">
                                                                    <td>
                                                                        <p>1. Book RO Service Near Me by calling us/filling the Enquiry form.</p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td>
                                                                        <p>2. Get technician confirmation call.</p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-success">
                                                                    <td>
                                                                        <p>3. Schedule your service.</p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td>
                                                                        <p>4. Technician will visit at your place.</p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-success">
                                                                    <td>
                                                                        <p>5. Service done.</p>
                                                                    </td>
                                                                </tr>
                                                                <tr className="table-primary">
                                                                    <td>
                                                                        <p>6. Make payment and share your valuable feedback.</p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>


                                                    <div className="tz">
                                                        <div className="tz-2 top-ms">
                                                            <div className="tz-2-com tz-2-main tz1">
                                                                <h4>How it Works </h4>
                                                                <div className="steps-of-work">
                                                                    <div className="step">
                                                                        <div className="heading a">Step 1</div>
                                                                        <div className="content">
                                                                            <img src="img/location/cont.png" alt="Book Service" />
                                                                            <p>Contact Us to Book Service</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="step">
                                                                        <div className="heading b">Step 2</div>
                                                                        <div className="content">
                                                                            <img src="img/location/sche.png" alt="Get Technician Confirmation" />
                                                                            <p>Get Technician Confirmation </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="step">
                                                                        <div className="heading c">Step 3</div>
                                                                        <div className="content">
                                                                            <img src="img/location/vis.png" alt="Technician will Visit &amp; Complete Work" />
                                                                            <p>Technician will Visit &amp; Complete Work</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="step">
                                                                        <div className="heading d">Step 4</div>
                                                                        <div className="content">
                                                                            <img src="img/location/wal.png" alt="Pay after Service Done" />
                                                                            <p>Pay after Service Done </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="tz-2-com tz-2-main tz2">
                                                                <h4> All RO Water Purifier Brands in {title} </h4>
                                                                <div className="main2 pregrahd" style={{ display: 'inline-block', width: '100%', flexDirection: 'column' }}>
                                                                    <p><a href="/" className="btn">Kent</a></p>
                                                                    <p><a href="/" className="btn">Aquaguard</a></p>
                                                                    <p><a href="/" className="btn">Eureka Forbes</a></p>
                                                                    <p><a href="/" className="btn">A.O. Smith</a></p>
                                                                    <p><a href="/" className="btn">Blue Star</a></p>
                                                                    <p><a href="/" className="btn">Aqua Fresh</a></p>
                                                                    <p><a href="/" className="btn">Livpure</a></p>
                                                                    <p><a href="/" className="btn">Pureit</a></p>
                                                                    <p><a href="/" className="btn"> Havells</a></p>
                                                                    <p><a href="/" className="btn">LG</a></p>
                                                                    <p><a href="/" className="btn">Whirlpool</a></p>
                                                                    <p><a href="/" className="btn">MarQ</a></p>
                                                                </div>
                                                            </div>

                                                            <div className="tz-2-com tz-2-main tz4">
                                                                <h4> Frequently Asked Questions</h4>
                                                                <div className="col-md-12 main4">
                                                                    <div className="demo">
                                                                        <div itemscope="" itemtype="http://schema.org/FAQPage">

                                                                            <div className="panel-group" id="accordion">
                                                                                <div className="card panel panel-default">
                                                                                    <div className="card-header" id="headingOne">
                                                                                        <h5 className="mb-0">
                                                                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                                                <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> RO Service Center Toll Free No in {title} ?
                                                                                            </button>
                                                                                        </h5>
                                                                                        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                                                            <div className="panel-body" itemprop="text">
                                                                                                RO Customer Care Number is <strong>7762998962</strong>.
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                                <div className="card panel panel-default">
                                                                                    <div className="card-header" id="headingTwo">
                                                                                        <h5 className="mb-0">
                                                                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                                                <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Why RO Service is Needed in {title} ?
                                                                                            </button>
                                                                                        </h5>
                                                                                    </div>
                                                                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                                                        <div className="panel-body" itemprop="text">
                                                                                            Buying a RO water purifier is not enough. You have to maintain it properly to ensure that harmful contaminants don?t pollute your drinking water.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card panel panel-default">
                                                                                    <div className="card-header" id="headingThree">
                                                                                        <h5 className="mb-0">
                                                                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                                                <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> How Much Time is Required For RO Installation in {title} ?
                                                                                            </button>
                                                                                        </h5>
                                                                                    </div>
                                                                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                                                        <div className="panel-body" itemprop="text">
                                                                                            We provides RO service in {title} in same day when service is booked. In Some Place we provides services within 90 minutes. No other service provider is as quick as H2O RO Services installation service centre.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card panel panel-default">
                                                                                    <div className="card-header" id="headingOne">
                                                                                        <h5 className="mb-0">
                                                                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                                                                                <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Why Should I Purchase RO AMC Plans in {title} ?
                                                                                            </button>
                                                                                        </h5>
                                                                                    </div>

                                                                                    <div id="collapseFour" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                                                                        <div className="panel-body" itemprop="text">
                                                                                            Your water purifier demands regular repair, maintenance, and service to effective purification, which can be costly. RO AMC plans in {title} offer periodic maintenance and repair service of your water purifier. They provide various kinds of customized AMC plans, thus choose according to your budget and requirement.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card panel panel-default">
                                                                                    <div className="card-header" id="headingFive">
                                                                                        <h5 className="mb-0">
                                                                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                                                                                                <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> When Should I Go For RO Repair Services in {title} ?
                                                                                            </button>
                                                                                        </h5>
                                                                                    </div>

                                                                                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                                                                        <div className="panel-body" itemprop="text">
                                                                                            Your water purifier starts showing some signs for it like odour in purified water, leakage, suddenly stop working and many other. Identify these signs and book RO repair services from H2O RO Services. you can book your service by calling on <a href="tel:7762998962">+91-7762998962</a>. You can also register your service / complain through Whatsapp <a href="https://api.whatsapp.com/send?phone=917762998962" target="_blank">+91-7762998962</a> or Visit <a href="www.h2oroservices.com" target="_blank">www.h2oroservices.com</a> for more details.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card panel panel-default">
                                                                                    <div className="card-header" id="headingSix">
                                                                                        <h5 className="mb-0">
                                                                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                                                                                <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Why Choose RO Water Purifier service Center in {title} ?
                                                                                            </button>
                                                                                        </h5>
                                                                                    </div>

                                                                                    <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                                                                        <div className="panel-body" itemprop="text">
                                                                                            RO water purifier service centre in {title} is available for all process such as installation, maintenance, and repairing of water purifier. They also understand customer budget and requirement thus choose H2O RO Services at best and affordable market price In PAN India.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card panel panel-default">
                                                                                    <div className="card-header" id="headingSeven">
                                                                                        <h5 className="mb-0">
                                                                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                                                                                                <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Do you charge for inspection and diagnosis of the problem?
                                                                                            </button>
                                                                                        </h5>
                                                                                    </div>

                                                                                    <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                                                                                        <div className="panel-body" itemprop="text">
                                                                                            Yes, we charge Rs 300/- as a visiting charge. However, this price may vary from place to place. And once you ask our service engineer to resolve the issues, this price gets adjusted.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card panel panel-default">
                                                                                    <div className="card-header" id="headingEight">
                                                                                        <h5 className="mb-0">
                                                                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                                                                                                <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Which brands of water purifiers do you repair?
                                                                                            </button>
                                                                                        </h5>
                                                                                    </div>

                                                                                    <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordion">
                                                                                        <div className="panel-body" itemprop="text">
                                                                                            We mainly deal in Kent, Aqua Guard, Aqua Fresh, Eureka Forbes, LivPure, PureIt and various other brands water purifiers. However, our RO water purifier services are available for all brands and kinds of water purifiers. So whenever you need services for your water purifier, do contact us via phone call or SMS.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>



                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                        <section className="AMC_pans__section position-relative pt-0 pb-5" id="amc" style={{ padding: "40px 0", backgroundColor: 'white' }}>
                            <section className="container" style={{ display: 'block' }}>
                                <section className="row amcsdd" id="#amcaccordion">
                                    <div className="widget-title wow fadeInUp text-center mb-1" style={{ width: '100%', position: 'relative' }}>
                                        <h4 className="fonts4em fontw600" style={{ fontSize: '30px' }}>Book AMC Plans </h4>
                                    </div>

                                    <div className="amc__item">
                                        <div className="main_section_sec mt-3 mb-2">
                                            <div className="service_tile_contnt">
                                                <h4>AMC Plan - 1</h4>
                                                <p>Annual maintenance plan for your RO covering 3-4 routine services.</p>
                                            </div>
                                            <div className="service_price">
                                                <i className="fas fa-rupee-sign"></i> 999/-
                                            </div>
                                            <div className="book_now_btions">
                                                <a href="#" data-toggle="modal" data-target="#newModalForWizard">Book Now</a>
                                            </div>
                                            <div className="down_arrow" id="amcheadingOne" >
                                                <button className="nav-toggle collapsed" data-toggle="collapse" data-target="#amccollapseOne" aria-expanded="true" aria-controls="amccollapseOne"><i className="fas fa-chevron-down"></i></button>
                                            </div>
                                            <div id="amccollapseOne" className="cotentss collapse" aria-labelledby="amcheadingOne" data-parent="#amcaccordion">
                                                <div className="pricing-features">
                                                    <div className="feature">Service<span>Include</span> <span className="mnss"><img src="img/location/mark.png" alt="RO Service" loading="lazy" /></span></div>
                                                    <div className="feature">Filters<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="Filter" loading="lazy" /></span></div>
                                                    <div className="feature">Membrane<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="Membrane" loading="lazy" /></span></div>
                                                    <div className="feature">Electric Parts<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="Electric Parts" loading="lazy" /></span></div>
                                                    <div className="feature">Faulty Parts<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="RO Service" loading="lazy" /></span></div>
                                                    <div className="note_for_price"><strong><i className="fa fa-exclamation-circle" aria-hidden="true"></i> Note:</strong> H2O RO Services India Spare Parts will be used in AMC Plans.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="amc__item">
                                        <div className="main_section_sec mt-3 mb-2">
                                            <div className="service_tile_contnt">
                                                <h4>AMC Plan - 2</h4>
                                                <p>Annual maintenance plan for RO covering routine services and filters.</p>
                                            </div>
                                            <div className="service_price">
                                                <i className="fas fa-rupee-sign"></i> 2425/-
                                            </div>
                                            <div className="book_now_btions">
                                                <a href="#" data-toggle="modal" data-target="#newModalForWizard">Book Now</a>
                                            </div>
                                            <div className="down_arrow" id="amcheadingTwo">
                                                <button className="nav-toggle collapsed" data-toggle="collapse" data-target="#amccollapseTwo" aria-expanded="true" aria-controls="amccollapseTwo"><i className="fas fa-chevron-down"></i></button>
                                            </div>
                                            <div id="amccollapseTwo" className="cotentss collapse" aria-labelledby="amcheadingTwo" data-parent="#amcaccordion">
                                                <div className="pricing-features">
                                                    <div className="feature">Service<span>Include</span> <span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Filters<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Membrane<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Electric Parts<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Faulty Parts<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="note_for_price"><strong><i className="fa fa-exclamation-circle" aria-hidden="true"></i> Note:</strong>  H2O RO Service parts will be used in AMC Plans.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="amc__item">
                                        <div className="main_section_sec mt-3 mb-2">
                                            <div className="service_tile_contnt">
                                                <h4>AMC Plan - 3</h4>
                                                <p>Annual maintenance plan for your RO covering routine services and electrical parts.</p>
                                            </div>
                                            <div className="service_price">
                                                <i className="fas fa-rupee-sign"></i> 2500/-
                                            </div>
                                            <div className="book_now_btions">
                                                <a href="#" data-toggle="modal" data-target="#newModalForWizard">Book Now</a>
                                            </div>
                                            <div className="down_arrow" id="amcheadingThree">
                                                <button className="nav-toggle collapsed" data-toggle="collapse" data-target="#amccollapseThree" aria-expanded="true" aria-controls="amccollapseThree"><i className="fas fa-chevron-down"></i></button>
                                            </div>
                                            <div id="amccollapseThree" className="cotentss collapse" aria-labelledby="amcheadingThree" data-parent="#amcaccordion">
                                                <div className="pricing-features">
                                                    <div className="feature">Service<span>Include</span> <span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Filters<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Membrane<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Electric Parts<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Faulty Parts<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="note_for_price"><strong><i className="fa fa-exclamation-circle" aria-hidden="true"></i> Note:</strong> H2O RO Services India Spare Parts will be used in AMC Plans.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="amc__item">
                                        <div className="main_section_sec mt-3 mb-2">
                                            <div className="service_tile_contnt">
                                                <h4>AMC Plan - 4</h4>
                                                <p>Annual maintenance plan for your RO covering routine services, filters and membrane.</p>
                                            </div>
                                            <div className="service_price">
                                                <i className="fas fa-rupee-sign"></i> 4000/-
                                            </div>
                                            <div className="book_now_btions">
                                                <a href="#" data-toggle="modal" data-target="#newModalForWizard">Book Now</a>
                                            </div>
                                            <div className="down_arrow" id="amcheadingFour">
                                                <button className="nav-toggle collapsed" data-toggle="collapse" data-target="#amccollapseFour" aria-expanded="true" aria-controls="amccollapseFour"><i className="fas fa-chevron-down"></i></button>
                                            </div>
                                            <div id="amccollapseFour" className="cotentss collapse" aria-labelledby="amcheadingFour" data-parent="#amcaccordion">
                                                <div className="pricing-features">
                                                    <div className="feature">Service<span>Include</span> <span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Filters<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Membrane<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Electric Parts<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Faulty Parts<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="note_for_price"><strong><i className="fa fa-exclamation-circle" aria-hidden="true"></i> Note:</strong> H2O RO Services India Spare Parts will be used in AMC Plans.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="amc__item">
                                        <div className="main_section_sec mt-3 mb-2">
                                            <div className="service_tile_contnt">
                                                <h4>AMC Plan - 5</h4>
                                                <p>Annual maintenance plan for your RO covering routine services, filters, membrane and electric parts.</p>
                                            </div>
                                            <div className="service_price">
                                                <i className="fas fa-rupee-sign"></i> 5000/-
                                            </div>
                                            <div className="book_now_btions">
                                                <a href="#" data-toggle="modal" data-target="#newModalForWizard">Book Now</a>
                                            </div>
                                            <div className="down_arrow" id="amcheadingFive">
                                                <button className="nav-toggle collapsed" data-toggle="collapse" data-target="#amccollapseFive" aria-expanded="true" aria-controls="amccollapseFive"><i className="fas fa-chevron-down"></i></button>
                                            </div>
                                            <div id="amccollapseFive" className="cotentss collapse" aria-labelledby="amcheadingFive" data-parent="#amcaccordion">
                                                <div className="pricing-features">
                                                    <div className="feature">Service<span>Include</span> <span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Filters<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Membrane<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Electric Parts<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Faulty Parts<span>Exclude</span><span className="mnss"><img src="img/location/cross.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="note_for_price"><strong><i className="fa fa-exclamation-circle" aria-hidden="true"></i> Note:</strong> H2O RO Services India Spare Parts will be used in AMC Plans.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="amc__item">
                                        <div className="main_section_sec mt-3 mb-2">
                                            <div className="service_tile_contnt">
                                                <h4>AMC Plan - 6</h4>
                                                <p>Annual maintenance plan for your RO covering routine services, filters, membrane, electric parts and faulty parts.</p>
                                            </div>
                                            <div className="service_price">
                                                <i className="fas fa-rupee-sign"></i> 6000/-
                                            </div>
                                            <div className="book_now_btions">
                                                <a href="#" data-toggle="modal" data-target="#newModalForWizard">Book Now</a>
                                            </div>
                                            <div className="down_arrow" id="amcheadingSix">
                                                <button className="nav-toggle collapsed" data-toggle="collapse" data-target="#amccollapseSix" aria-expanded="true" aria-controls="amccollapseSix"><i className="fas fa-chevron-down"></i></button>
                                            </div>
                                            <div id="amccollapseSix" className="cotentss collapse" aria-labelledby="amcheadingSix" data-parent="#amcaccordion">
                                                <div className="pricing-features">
                                                    <div className="feature">Service<span>Include</span> <span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Filters<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Membrane<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Electric Parts<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="feature">Faulty Parts<span>Include</span><span className="mnss"><img src="img/location/mark.png" alt="right mark" loading="lazy" /></span></div>
                                                    <div className="note_for_price"><strong><i className="fa fa-exclamation-circle" aria-hidden="true"></i> Note:</strong> H2O RO Services India Spare Parts will be used in AMC Plans.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </section>
                            </section>
                        </section>
                    </div>
                </div>
            </div>
            <Popularcity />
        </>
    )
}
import React from 'react';

export const Faq = (props) => {
    return (
        <div id='faq'>
            <div className="container">
                <div className='section-title text-center'>
                    <h2>Frequently Asked Questions</h2>
                </div>
                <div id="accordion" className="panel-group">
                    <div className="card panel panel-default">
                        <div className="card-header" id="headingOne">
                            <h5 className="mb-0">
                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i className="more-less glyphicon glyphicon-plus">&nbsp;</i>Do I Need To Get AC Service Regularly?
                                </button>
                            </h5>
                        </div>

                        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                            <div className="card-body">
                                Yes, your AC unit is an electronic device which acquires wear and tears as a result it starts to sound annoying and consumes more electricity. So you need to get your AC unit service done every season.
                            </div>
                        </div>
                    </div>
                    <div className="card panel panel-default">
                        <div className="card-header" id="headingTwo">
                            <h5 className="mb-0">
                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i className="more-less glyphicon glyphicon-plus">&nbsp;</i>Where Should I Get AC Service Done?
                                </button>
                            </h5>
                        </div>
                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div className="card-body">
                            AC system is a complex electronic device which contains several spare which may get damaged if not treated by new hand thus always ensure to hire the professional service engineer and for this, you can get in touch with a well-trained and service engineer.
                            </div>
                        </div>
                    </div>
                    <div className="card panel panel-default">
                        <div className="card-header" id="headingThree">
                            <h5 className="mb-0">
                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i className="more-less glyphicon glyphicon-plus">&nbsp;</i>How To Find the Best AC Service Provider?
                                </button>
                            </h5>
                        </div>
                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                            <div className="card-body">
                                Finding the best AC service provider is tricky and for this, you need expert help or should perform various research. You can find previous customer reviews of the selected AC service provider as this will help you to know is your AC service provider reliable or not. You can also compare the AC service price of the AC service provider.
                            </div>
                        </div>
                    </div>
                    <div className="card panel panel-default">
                        <div className="card-header" id="headingFour">
                            <h5 className="mb-0">
                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <i className="more-less glyphicon glyphicon-plus">&nbsp;</i>Which AC is best for your Home?
                                </button>
                            </h5>
                        </div>
                        <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                            <div className="card-body">
                                The best AC for your Home depends on several factors, such as the room's size, budget, and specific cooling requirements. You can find various air conditioners at Vijay Sales suitable for different home sizes and budgets. Split ACs are generally more suitable for home use, as they are quieter and more efficient than window ACs. It's important to choose an AC with the appropriate cooling capacity for your room size and to consider features such as energy efficiency, noise level, and air filtration capabilities.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
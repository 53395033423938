import React from 'react';

export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro acintro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <div className="call-action-area">
                  <span className="number"><a href="tel:9031062012" style={{ fontWeight: 'bold' }}><i className="fa fa-phone"></i>  +91-9031062012 </a></span>
                  <span className="number"><a href="https://api.whatsapp.com/send?phone=919031062011" target="_blank" style={{ fontWeight: 'bold' }}><i className="fa fa-whatsapp" style={{ backgroundColor: '#25D366' }}></i>  +91-9031062012 </a></span>
                  {/*<span className="request"><a data-toggle="modal" data-target="#newModalForWizard">Request a Call Back</a></span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
